import { EventType } from "@/analytics/analytics-events";
import { changeMode } from "@/store/mode-slice";
import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { resetAlignmentWizard } from "@/store/modes/alignment-wizard-mode-slice";
import {
  AlignmentReference,
  setAlignmentReference,
  setCloudForCloudAlignment,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import {
  setSheetForCadAlignment,
  setSheetToCadAlignmentStep,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import {
  setReferenceCloudForAlignment,
  setSheetIdForAlignment,
  setStepForSheetToCloudAlignment,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  redirectToAlignmentTool,
  redirectToRegistrationTool,
} from "@/utils/redirects";
import { useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  IElementTypeHint,
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementGenericModel3d,
  isIElementGenericPointCloudStream,
  isIElementModel3dStream,
  isIElementPointCloudStream,
  isIElementSectionDataSession,
  isIElementSectionWithTypeHint,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
  selectProjectId,
} from "@faro-lotv/project-source";
import { useCallback, useEffect } from "react";
import { selectIsPointCloudViewable } from "../mode-selectors";
import { AlignWizardProgressBar } from "./align-wizard-progress-bar";
import { AlignWizardSplitScreen } from "./align-wizard-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function AlignWizardModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const { openToast } = useToast();

  const projectId = useAppSelector(selectProjectId);
  assert(projectId, "invalid number project id");

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);

  const alignedElement = useAppSelector(selectIElement(elementToAlignId));
  assert(
    alignedElement,
    "It should be not possible to open Alignment Wizard without aligned element being selected",
  );

  useEffect(() => {
    if (
      isIElementSectionWithTypeHint(
        alignedElement,
        IElementTypeHint.dataSetFocus,
      ) ||
      isIElementSectionWithTypeHint(alignedElement, IElementTypeHint.dataSetEls)
    ) {
      openToast({
        title: "Align point cloud to 3D model",
        variant: "info",
        message:
          "SCENE point clouds cannot be aligned to 3D models at this time.\nSelect a 3D model and click 'Align' to align it to the point cloud.",
      });
    }
  }, [alignedElement, openToast]);

  const selectAndRunAlignment = useCallback(() => {
    const referenceElement = selectIElement(referenceElementId)(
      store.getState(),
    );
    if (referenceElement === undefined) return;

    if (isIElementAreaSection(alignedElement)) {
      assert(
        isIElementGenericModel3d(referenceElement) ||
          isIElementSectionDataSession(referenceElement),
        "invalid element type",
      );

      const sheet = selectChildDepthFirst(
        alignedElement,
        isIElementGenericImgSheet,
      )(store.getState());
      assert(sheet, "Sheet not found for selected floor.");

      if (isIElementGenericModel3d(referenceElement)) {
        // sheet to cad alignment
        dispatch(
          setSheetToCadAlignmentStep(SheetToCadAlignmentStep.setElevation),
        );
        dispatch(setSheetForCadAlignment(sheet.id));
        dispatch(changeMode("sheetToCadAlignment"));
      } else {
        // sheet to cloud alignment
        const cloud = selectChildDepthFirst(
          referenceElement,
          isIElementGenericPointCloudStream,
        )(store.getState());
        assert(
          cloud,
          "Sheet-to-cloud alignment should not be called when there is no point cloud selected",
        );

        dispatch(
          setStepForSheetToCloudAlignment(
            SheetToCloudAlignmentStep.setElevation,
          ),
        );
        dispatch(setSheetIdForAlignment(sheet.id));
        dispatch(setReferenceCloudForAlignment(cloud.id));
        dispatch(changeMode("sheetToCloudAlignment"));
      }
    } else if (isIElementSectionDataSession(alignedElement)) {
      if (isIElementAreaSection(referenceElement)) {
        redirectToAlignmentTool({
          projectId,
          elementId: alignedElement.id,
          floorId: referenceElement.id,
          state: store.getState(),
          dispatch,
        });
      } else if (isIElementSectionDataSession(referenceElement)) {
        Analytics.track(EventType.startPairwiseRegistrationWorkflow);

        redirectToRegistrationTool({
          projectId,
          pointCloudId1: referenceElement.id,
          pointCloudId2: alignedElement.id,
        });
      } else if (isIElementModel3dStream(referenceElement)) {
        const cloud = selectChildDepthFirst(
          alignedElement,
          isIElementGenericPointCloudStream,
        )(store.getState());

        assert(
          cloud,
          "Cloud-To-Cad alignment should not be called when there is no point cloud selected",
        );

        dispatch(setAlignmentReference(AlignmentReference.bimModel));
        dispatch(setCloudForCloudAlignment(cloud.id));
        dispatch(changeMode("cloudToCadAlignment"));
      } else {
        throw new Error(
          "Invalid combination of reference and aligned elements in Alignment Wizard",
        );
      }
    } else if (isIElementModel3dStream(alignedElement)) {
      const cloud = selectChildDepthFirst(
        referenceElement,
        (el) =>
          isIElementPointCloudStream(el) &&
          selectIsPointCloudViewable(el)(store.getState()),
      )(store.getState());

      assert(
        cloud,
        "Cad-To-Cloud alignment should not be called when there is no point cloud selected",
      );
      dispatch(setAlignmentReference(AlignmentReference.pointCloud));
      dispatch(setCloudForCloudAlignment(cloud.id));
      dispatch(changeMode("cloudToCadAlignment"));
    } else if (
      isIElementSectionWithTypeHint(
        alignedElement,
        IElementTypeHint.dataSetFocus,
      ) ||
      isIElementSectionWithTypeHint(alignedElement, IElementTypeHint.dataSetEls)
    ) {
      const cloud = selectChildDepthFirst(
        alignedElement,
        (el) =>
          isIElementPointCloudStream(el) &&
          selectIsPointCloudViewable(el)(store.getState()),
      )(store.getState());

      assert(
        cloud,
        "Cloud-To-Cad alignment should not be called when there is no point cloud selected",
      );
      dispatch(setAlignmentReference(AlignmentReference.pointCloud));
      dispatch(setCloudForCloudAlignment(cloud.id));
      dispatch(changeMode("cloudToCadAlignment"));
    }
    dispatch(resetAlignmentWizard());
  }, [alignedElement, dispatch, projectId, referenceElementId]);

  return (
    <>
      <AlignWizardProgressBar goToNextStep={selectAndRunAlignment} />
      <AlignWizardSplitScreen />
    </>
  );
}
