import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { useObjectView } from "@/hooks/use-object-view";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { useBoxControlsClippingPlanes } from "@/utils/box-controls-context";
import { createClippingPlanes, volumeFromPlanes } from "@/utils/volume-utils";
import { blue, neutral } from "@faro-lotv/flat-ui";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { LotvRenderer, assert, computeOrthophotoCamera } from "@faro-lotv/lotv";
import { selectIElementWorldTransform } from "@faro-lotv/project-source";
import { Box } from "@mui/system";
import { OrthographicCamera, PerformanceMonitor } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Color,
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Quaternion,
  OrthographicCamera as ThreeOrthoCamera,
  Vector3,
} from "three";
import { useOrthophotoViewDirection } from "./use-orthophoto-view-direction";

const ASPECT_RATIO = 1.75;

/**
 * @returns A preview of the orthophoto that will be extracted
 */
export function OrthophotoPreview(): JSX.Element {
  const createRenderer = useCallback(
    (canvas: HTMLCanvasElement | OffscreenCanvas) => {
      const renderer = new LotvRenderer({
        canvas,
        premultipliedAlpha: false,
      });
      // enabling the 'localClippingEnabled' property
      // since the app is going to use a global bounding box
      // in most of its scenes.
      renderer.localClippingEnabled = true;
      return renderer;
    },
    [],
  );

  return (
    <Box
      component="span"
      sx={{
        width: "100%",
        AspectRatio: `${ASPECT_RATIO}`,
      }}
    >
      <Canvas
        gl={createRenderer}
        onCreated={(state) => (state.scene.background = new Color(neutral[50]))}
      >
        <PerformanceMonitor>
          <OrthophotoScene />
        </PerformanceMonitor>
      </Canvas>
    </Box>
  );
}

/**
 * @returns The scene rendered inside the Orthophoto preview
 */
function OrthophotoScene(): JSX.Element {
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "The overview image preview requires a point cloud",
  );

  const transform = useAppSelector(selectIElementWorldTransform(main.id));
  const pointCloud = useCached3DObject(main);

  // Create a view of the pointcloud so that it can be shown both in the main scene and the overview
  const view = useObjectView(pointCloud);

  const clippingPlanesBox = useBoxControlsClippingPlanes();

  // Clipping planes correctly positioned in the scene
  const pcClippingBoxPlanes = useMemo(() => {
    if (!clippingPlanesBox) return;
    return createClippingPlanes(clippingPlanesBox, transform);
  }, [clippingPlanesBox, transform]);

  const pcClippingPlanes = useMemo(() => {
    return pcClippingBoxPlanes
      ? [...pcClippingBoxPlanes.min, ...pcClippingBoxPlanes.max]
      : undefined;
  }, [pcClippingBoxPlanes]);

  // Update the camera position and orientation to frame the pointcloud
  const volume = useMemo(() => {
    if (!clippingPlanesBox) return;

    const v = volumeFromPlanes(clippingPlanesBox, transform);
    if (!v?.position || !v.rotation || !v.size) return;

    return {
      position: new Vector3(v.position.x, v.position.y, v.position.z),
      quaternion: new Quaternion(
        v.rotation.x,
        v.rotation.y,
        v.rotation.z,
        v.rotation.w,
      ),
      size: new Vector3(v.size.x, v.size.y, v.size.z),
    };
  }, [clippingPlanesBox, transform]);

  // View direction of the camera
  const viewDir = useOrthophotoViewDirection(volume);

  // The group wrapping the background plane
  const [group] = useState(() => new Group());

  // Group used to properly position the helper plane
  const { camera, gl } = useThree();
  useEffect(() => {
    if (!volume || !viewDir) return;
    const newCamera = computeOrthophotoCamera(
      volume,
      viewDir,
      new Vector3(0, 1, 0),
      new Vector3(1, 0, 0),
    );

    if (camera instanceof ThreeOrthoCamera) {
      camera.copy(newCamera);

      const lookAtDirection = camera.getWorldDirection(new Vector3());
      group.position
        .copy(camera.position)
        .add(lookAtDirection.multiplyScalar(camera.far));
      group.quaternion.copy(camera.quaternion);
      group.scale.set(camera.right * 2, camera.top * 2, 1);

      if (camera.right / camera.top > ASPECT_RATIO) {
        camera.top = camera.right / ASPECT_RATIO;
        camera.bottom = -camera.top;
      } else {
        camera.right = camera.top * ASPECT_RATIO;
        camera.left = -camera.right;
      }

      camera.updateMatrixWorld();
      camera.updateProjectionMatrix();
    }
  }, [viewDir, volume, camera, gl, group]);

  // A background plane used to highlight the border of the actual orthophoto
  const planeHelper = useMemo(
    () =>
      new Mesh(
        new PlaneGeometry(),
        new MeshBasicMaterial({
          transparent: true,
          opacity: 0.3,
          color: blue[100],
        }),
      ),
    [],
  );

  return (
    <>
      <primitive object={group}>
        <primitive object={planeHelper} />
      </primitive>
      <OrthographicCamera makeDefault />
      <PointCloudRenderer pointCloud={view} clippingPlanes={pcClippingPlanes} />
    </>
  );
}
