import { MeasureLabel } from "@/components/r3f/renderers/measurements/measure-label";
import { useToggleUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import {
  getAnalysisReferencePlane,
  POLYGON_SELECTION_PLANE_THRESHOLD,
} from "@/utils/colormap-analysis-utils";
import {
  getPickedPoint,
  useOverrideCursor,
} from "@faro-lotv/app-component-toolbox";
import {
  createProjectedPolygon2d,
  isPointInProjectedPolygon,
  ProjectedPolygon2d,
} from "@faro-lotv/lotv";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Plane, Vector3 } from "three";
import { ToolCallback, ToolControlsRef } from "./tool-controls-interface";

type PointCloudAnalysisLabelToolProps = {
  /** True if this tool is the active one */
  isActive: boolean;

  /** The active analysis object */
  activeAnalysis: PointCloudAnalysis;
};

/** @returns The tool used to create labels on an existing analysis */
export const PointCloudAnalysisLabelTool = forwardRef<
  ToolControlsRef,
  PointCloudAnalysisLabelToolProps
>(function PointCloudAnalysisTool(
  { isActive, activeAnalysis }: PointCloudAnalysisLabelToolProps,
  ref,
): JSX.Element | null {
  const referencePlane = useMemo(() => {
    const plane = getAnalysisReferencePlane(activeAnalysis);
    if (!plane) return;
    return new Plane().setFromNormalAndCoplanarPoint(plane.normal, plane.point);
  }, [activeAnalysis]);

  const [labelPosition, setLabelPosition] = useState<Vector3>();
  const [labelDistance, setLabelDistance] = useState<number>(0.0);

  const [projectedPolygon2d, setProjectedPolygon2d] =
    useState<ProjectedPolygon2d>();
  useEffect(() => {
    const computePolygon = async (): Promise<void> => {
      const polygon = activeAnalysis.polygonSelection.map((p) =>
        new Vector3().fromArray(p),
      );
      const projPolygon = await createProjectedPolygon2d(polygon);
      setProjectedPolygon2d(projPolygon);
    };
    computePolygon().catch((error) => {
      console.error(error);
      setProjectedPolygon2d(undefined);
    });
  }, [activeAnalysis.polygonSelection]);

  const pointHovered = useCallback<ToolCallback>(
    (ev, iElementId) => {
      if (!projectedPolygon2d) return;

      // only show preview if the mouse hovers on the analysis
      if (iElementId !== activeAnalysis.parentId) {
        setLabelPosition(undefined);
        return;
      }
      const pickedPoint = getPickedPoint(ev);
      const distanceThreshold = POLYGON_SELECTION_PLANE_THRESHOLD / 2.0;
      if (
        !isPointInProjectedPolygon(
          pickedPoint,
          projectedPolygon2d,
          distanceThreshold,
        )
      ) {
        setLabelPosition(undefined);
        return;
      }

      ev.stopPropagation();
      setLabelPosition(pickedPoint);
      const distance = referencePlane
        ? referencePlane.distanceToPoint(pickedPoint)
        : 0.0;
      setLabelDistance(distance);
    },
    [activeAnalysis.parentId, projectedPolygon2d, referencePlane],
  );

  useImperativeHandle(ref, () => ({
    pointHovered,
  }));

  useOverrideCursor("crosshair", isActive);

  const { unitOfMeasure } = useToggleUnitOfMeasure(false);
  const labelContainer = useViewOverlayRef();

  if (!isActive) return null;

  return (
    <MeasureLabel
      index={0}
      visible
      position={labelPosition}
      parentRef={labelContainer}
      distance={labelDistance}
      unitOfMeasure={unitOfMeasure}
      active
      transparent
      pointerEvents="none"
      placement="top"
    />
  );
});
