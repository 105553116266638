import { useAppSelector } from "@/store/store-hooks";
import {
  LocalRegistrationEdge,
  RegistrationEdgeType,
  RevisionStatus,
} from "@faro-lotv/service-wires";
import { useMemo } from "react";
import {
  selectRegistrationEdgeType,
  selectShowConnectionLines,
} from "../store/data-preparation-view-options/data-preparation-view-options-selectors";
import { selectEdgesMap } from "../store/revision-selectors";
import { EdgesMap } from "../store/revision-slice";
import { ConnectionInfo } from "./types";

/**
 * Represents the result of determining whether to show connection lines.
 *
 * This type can be one of two shapes:
 *
 * 1. When `showConnectionLines` is `true`:
 *    - `showConnectionLines`: A boolean indicating that connection lines should be shown.
 *    - `localConnections`: An array of `ConnectionInfo` objects representing the local connections.
 *    - `registrationEdgeType`: A string indicating the type of registration edge.
 *
 * 2. When `showConnectionLines` is `false`:
 *    - `showConnectionLines`: A boolean indicating that connection lines should not be shown.
 */
type ActiveConnectionLinesResult =
  | {
      showConnectionLines: true;
      localConnections: ConnectionInfo[];
      registrationEdgeType: string;
    }
  | {
      showConnectionLines: false;
    };

/**
 *
 * @returns The local connections, whether to show connection lines, and the global or local results setting.
 */
export function useActiveConnectionLines(): ActiveConnectionLinesResult {
  // Determine whether to show connection lines
  const showConnectionLines = useAppSelector(selectShowConnectionLines);

  // Get the local registration edges from the store
  const edgesMap = useAppSelector(selectEdgesMap);

  // Include only edges that are of type local and are not marked for removal. Then convert back to a record
  const localEdges = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(edgesMap).filter(
          ([, edge]) =>
            edge?.type === RegistrationEdgeType.local &&
            edge.status !== RevisionStatus.deleted,
        ),
      ),
    [edgesMap],
  );

  // Get the global or local results setting from the store
  const registrationEdgeType = useAppSelector(selectRegistrationEdgeType);

  // Get the local edges as an array of LocalRevisionEdge objects
  const localConnections = useMemo(
    () =>
      showConnectionLines
        ? captureTreeConnections(getLocalRevisionEdgesArray(localEdges))
        : [],
    [showConnectionLines, localEdges],
  );

  if (!showConnectionLines) {
    return { showConnectionLines: false };
  }

  return { showConnectionLines: true, localConnections, registrationEdgeType };
}

/**
 * @param localEdgesRevision The local edges to capture the connections from.
 * @returns All local connections that have been registered.
 */
function captureTreeConnections(
  localEdgesRevision: LocalRegistrationEdge[],
): ConnectionInfo[] {
  return localEdgesRevision.map((localEdge) => ({
    sourceScanId: localEdge.sourceId,
    registrationObjectId: localEdge.id,
    targetScanId: localEdge.targetId,
    metrics: localEdge.data.metrics,
    ...localEdge,
  }));
}

// Function to get an array of LocalRegistrationEdge objects from an EdgesMap
function getLocalRevisionEdgesArray(
  edgesMap: EdgesMap,
): LocalRegistrationEdge[] {
  return Object.values(edgesMap).filter(
    (
      edge,
    ): edge is LocalRegistrationEdge & {
      status: RevisionStatus;
    } => edge !== undefined && edge.type === RegistrationEdgeType.local,
  );
}
