import { useSceneEvents } from "@/components/common/scene-events-context";
import { useActionBarAnchorPoint } from "@/hooks/use-actionbar-anchor-point";
import { PointCloudObject } from "@/object-cache";
import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import {
  computeColormapParameters,
  getAnalysisReferencePlane,
} from "@/utils/colormap-analysis-utils";
import { GridPlane, Z_TO_Y_UP_QUAT } from "@faro-lotv/app-component-toolbox";
import { AdaptivePointsMaterial } from "@faro-lotv/lotv";
import { useEffect, useMemo, useState } from "react";
import { Box3, Quaternion, Vector2, Vector3 } from "three";
import { AnalysisActionBar } from "./analysis-action-bar";

type ColorMapAnalysisRendererProps = {
  /** Point cloud object used by analysis */
  pointCloud: PointCloudObject;

  /** The point cloud analysis to be rendered */
  analysis: PointCloudAnalysis;
};

/** @returns The renderer of a colormap analysis */
export function ColormapAnalysisRenderer({
  pointCloud,
  analysis,
}: ColorMapAnalysisRendererProps): JSX.Element | null {
  const polygon = useMemo(
    () => analysis.polygonSelection.map((p) => new Vector3().fromArray(p)),
    [analysis.polygonSelection],
  );

  const [planePosition, setPlanePosition] = useState<Vector3>();
  const [planeQuaternion, setPlaneQuaternion] = useState<Quaternion>();
  const extents = useMemo(() => {
    const box = new Box3().setFromPoints(polygon);
    const size = box.getSize(new Vector3()).length();
    return new Vector2(size, size);
  }, [polygon]);

  useEffect(() => {
    const referencePlane = getAnalysisReferencePlane(analysis);
    if (!referencePlane) return;

    setPlanePosition(referencePlane.point);
    const quaternion = new Quaternion().setFromUnitVectors(
      new Vector3(0, 1, 0),
      referencePlane.normal,
    );
    quaternion.multiply(Z_TO_Y_UP_QUAT);
    setPlaneQuaternion(quaternion);
  }, [analysis]);

  const sceneEvents = useSceneEvents();

  useEffect(() => {
    const { material } = pointCloud;
    if (!(material instanceof AdaptivePointsMaterial)) {
      return;
    }
    const colormapParms = computeColormapParameters(pointCloud, analysis);
    if (!colormapParms) return;

    material.setColormap(colormapParms);
    // Sometime updating material does not trigger re-render PointCloudSubscene
    // Manually invalidate is required to make sure view is updated
    sceneEvents.invalidatePointCloudScene.emit();
    return () => {
      material.setColormap(undefined);
      sceneEvents.invalidatePointCloudScene.emit();
    };
  }, [analysis, pointCloud, sceneEvents.invalidatePointCloudScene]);

  // Anchor point for the action bar
  const anchorPoint = useActionBarAnchorPoint(polygon);

  return (
    <>
      <AnalysisActionBar anchorPoint={anchorPoint} analysis={analysis} />
      {analysis.showReferencePlane && (
        <group position={planePosition} quaternion={planeQuaternion}>
          <GridPlane size={extents} />
        </group>
      )}
    </>
  );
}
