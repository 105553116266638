import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { FaroButton } from "@faro-lotv/flat-ui";
import {
  isDefinedAndLoaded,
  isLoading,
  MaybeLoading,
  validateEnumValue,
} from "@faro-lotv/foundation";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import { RegistrationError } from "../ui/registered-status-banner";
import { PublishButton } from "./inspect-and-publish-publish-button";

interface InspectAndPublishSidebarButtonsProps {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Whether the report is currently open */
  isReportOpen: boolean;

  /** Callback, when the report should be opened/closed */
  onToggleReport(shouldOpen: boolean): void;
}

/** @returns the buttons for the data preparation sidebar in the inspect-and-publish step */
export function InspectAndPublishSidebarButtons({
  revision,
  registrationReport,
  isReportOpen,
  onToggleReport,
}: InspectAndPublishSidebarButtonsProps): JSX.Element {
  const [publishButtonWithWarning, setPublishButtonWithWarning] =
    useState(false);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
  const [disableQualityReportButton, setDisableQualityReportButton] =
    useState(false);

  useEffect(() => {
    if (isDefinedAndLoaded(registrationReport)) {
      const { additionalData } = registrationReport;
      if (
        additionalData &&
        validateEnumValue(additionalData.error, RegistrationError)
      ) {
        const registrationError = additionalData.error;

        setPublishButtonWithWarning(true);
        switch (registrationError) {
          case RegistrationError.NoError:
            setDisableQualityReportButton(false);
            setPublishButtonDisabled(false);
            break;
          case RegistrationError.NoRegistration:
            setDisableQualityReportButton(true);
            break;
          case RegistrationError.PublishFailed:
          case RegistrationError.MergeFailed:
          case RegistrationError.OtherError:
            setDisableQualityReportButton(false);
            setPublishButtonDisabled(true);
            break;
          // No need for default case as all errors set publishButtonWithWarning to true
        }
      }
    }
  }, [registrationReport]);
  return (
    <>
      <FaroButton
        variant="secondary"
        isLoading={isLoading(registrationReport)}
        disabled={!registrationReport || disableQualityReportButton}
        onClick={() => onToggleReport(!isReportOpen)}
      >
        {isReportOpen ? "Hide" : "Show"} quality report
      </FaroButton>

      <PublishButton
        revision={revision}
        disabledDueToError={publishButtonDisabled}
        showWithWarning={publishButtonWithWarning}
      />
    </>
  );
}
