import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { ImportSheetModeOverlay } from "./import-sheet-mode-overlay";
import { ImportSheetModeScene } from "./import-sheet-mode-scene";

export const importSheetMode: Mode = {
  name: "importSheet",
  ModeScene: ImportSheetModeScene,
  ModeOverlay: ImportSheetModeOverlay,
  exclusive: {
    title: "Import sheet",
    onBack({ dispatch }) {
      dispatch(changeMode("sheet"));
      return Promise.resolve();
    },
  },
};
