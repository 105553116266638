import { FaroButton, FaroText } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { OrthophotoPreview } from "./orthophoto-perview";

/** @returns the form to export an orthophoto from */
export function OrthophotoExportForm(): JSX.Element {
  function exportOrthophoto(): void {
    // eslint-disable-next-line no-alert
    alert("see https://faro01.atlassian.net/browse/SWEB-5702");
  }

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack gap={2}>
        <FaroText variant="bodyM">
          To change the side where to project the points click on the desired
          face of the box.
        </FaroText>

        <FaroText variant="labelM">Orthophoto preview</FaroText>
        <OrthophotoPreview />
      </Stack>

      <FaroButton
        onClick={exportOrthophoto}
        aria-label="start orthophoto export"
      >
        Export
      </FaroButton>
    </Stack>
  );
}
