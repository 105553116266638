import { ContextMenuAction, ContextMenuActionType } from "../action-types";
import { ADJUST_TRAJECTORY_ACTION } from "./adjust-trajectory-action";
import { OPEN_ALIGN_WIZARD_ACTION } from "./align-actions";
import { DELETE_ACTION } from "./delete-action";
import { DELETE_VIDEO_RECORDING_ACTION } from "./delete-video-recording-action";
import { DOWNLOAD_ACTION } from "./download-action";
import { EDIT_ELEMENT_ACTION } from "./edit-element-action";
import { GENERATE_FLOORPLAN } from "./generate-floorplan";
import { INSPECT_AND_PUBLISH_ACTION } from "./inspect-and-publish-action";
import { MOVE_DATA_SESSION_ACTION } from "./move-data-session-action";
import { REGISTER_ALL_ACTION } from "./register-dataset-action";
import { SCALE_FLOOR_ACTION } from "./scale-floor-action";
import { SET_AS_GEO_REFERENCED_ACTION } from "./set-as-geo-referenced";

/** Static actions to reuse inside context menus.  */
export const ACTIONS: Readonly<
  Record<ContextMenuActionType, ContextMenuAction>
> = {
  [ContextMenuActionType.adjustTrajectory]: ADJUST_TRAJECTORY_ACTION,
  [ContextMenuActionType.delete]: DELETE_ACTION,
  [ContextMenuActionType.deleteVideoRecording]: DELETE_VIDEO_RECORDING_ACTION,
  [ContextMenuActionType.download]: DOWNLOAD_ACTION,
  [ContextMenuActionType.editElement]: EDIT_ELEMENT_ACTION,
  [ContextMenuActionType.generateFloorplan]: GENERATE_FLOORPLAN,
  [ContextMenuActionType.inspectAndPublish]: INSPECT_AND_PUBLISH_ACTION,
  [ContextMenuActionType.moveDataSession]: MOVE_DATA_SESSION_ACTION,
  [ContextMenuActionType.openAlignmentWizard]: OPEN_ALIGN_WIZARD_ACTION,
  [ContextMenuActionType.registerAll]: REGISTER_ALL_ACTION,
  [ContextMenuActionType.scaleFloor]: SCALE_FLOOR_ACTION,
  [ContextMenuActionType.setCloudAsGeoReferenced]: SET_AS_GEO_REFERENCED_ACTION,
};
