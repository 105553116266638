import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { Tag } from "@/store/tags/tags-slice";
import {
  AnnotationEditorButtons,
  AnnotationEditorTypeSelection,
  AnnotationTypeDropdownProps,
  neutral,
} from "@faro-lotv/flat-ui";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { AccAnnotationForm } from "./acc-annotation-form";
import {
  EXTERNAL_ANNOTATION_DEFAULT_TITLE,
  FILL_MANDATORY_FIELDS_TOOLTIP,
} from "./annotation-props";
import { CreateAnnotationFormProps } from "./create-annotation-form";

type AccAnnotationProps = CreateAnnotationFormProps &
  AnnotationTypeDropdownProps & {
    /** The BCF Service integration type that corresponds to the ACC annotation to create */
    integrationType:
      | BcfServicesIntegrationType.autodeskAccIssues
      | BcfServicesIntegrationType.autodeskAccRfis;
  };

/**
 * @returns ACC annotation creation form
 */
export function AccAnnotation({
  onClose,
  onSave,
  integrationType: externalAnnotationType,
  selectedAnnotationType,
  onAnnotationTypeChange,
  annotationTypeOptions,
}: AccAnnotationProps): JSX.Element {
  const { handleErrorWithToast } = useErrorHandlers();

  const [isSaving, setIsSaving] = useState(false);
  const [areMandatoryFieldsFilled, setAreMandatoryFieldsFilled] =
    useState(false);
  const [title, setTitle] = useState(EXTERNAL_ANNOTATION_DEFAULT_TITLE);
  const [assignee, setAssignee] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [dueDate, setDueDate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>();
  const [description, setDescription] = useState<string>("");
  const [rootCause, setRootCause] = useState<string>("");
  const [topicType, setTopicType] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const [selectedWatchers, setSelectedWatchers] = useState<Tag[]>([]);
  const [published, setPublished] = useState(false);

  useEffect(() => {
    // Reset the fields when the annotation type changes
    setTitle(EXTERNAL_ANNOTATION_DEFAULT_TITLE);
    setAssignee("");
    setStatus("");
    setDueDate(undefined);
    setStartDate(undefined);
    setDescription("");
    setRootCause("");
    setPriority("");
    setTopicType("");
    setSelectedWatchers([]);
  }, [externalAnnotationType]);

  const createAnnotation = useCallback(async () => {
    setIsSaving(true);
    try {
      await onSave({
        title,
        assignee,
        description,
        dueDate,
        status,
        externalAnnotationType,
        accCompanionFields: {
          selectedWatchers,
          rootCause,
          startDate,
          published,
        },
        topicType,
        priority,
      });
    } catch (error) {
      handleErrorWithToast({ title: "Saving annotation failed", error });
    }
    setIsSaving(false);
  }, [
    onSave,
    title,
    assignee,
    description,
    dueDate,
    status,
    externalAnnotationType,
    selectedWatchers,
    rootCause,
    startDate,
    topicType,
    priority,
    published,
    handleErrorWithToast,
  ]);

  return (
    <Stack
      direction="column"
      sx={{
        width: "522px",
        backgroundColor: neutral[999],
        p: 2,
        borderRadius: "4px",
        pointerEvents: isSaving ? "none" : undefined,
        flexGrow: 1,
        gap: 3,
        // Allow the main content to scroll if there's not enough space
        overflowY: "auto",
        // but do not add an horizontal scrollbar when the main content needs to scroll
        overflowX: "clip",
      }}
    >
      <AnnotationEditorTypeSelection
        value={selectedAnnotationType}
        options={annotationTypeOptions}
        onChange={(e) => onAnnotationTypeChange(e.target.value)}
        disabled={isSaving}
      />
      <AccAnnotationForm
        title={title}
        allowEdition={!isSaving}
        assignee={assignee}
        startDate={startDate}
        dueDate={dueDate}
        status={status}
        topicType={topicType}
        priority={priority}
        rootCause={rootCause}
        selectedWatchers={selectedWatchers}
        published={published}
        externalAnnotationType={externalAnnotationType}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
        onAssigneeChange={setAssignee}
        onStartDateChange={setStartDate}
        onDueDateChange={setDueDate}
        onStatusChange={setStatus}
        onRootCauseChange={setRootCause}
        onTopicTypeChange={setTopicType}
        onPriorityChange={setPriority}
        onSelectedWatchersChange={setSelectedWatchers}
        onPublishedChange={setPublished}
        setAreMandatoryFieldsFilled={setAreMandatoryFieldsFilled}
      />
      <AnnotationEditorButtons
        isConfirmButtonDisabled={!areMandatoryFieldsFilled}
        disabled={isSaving}
        confirmButtonText={isSaving ? "Creating" : "Create"}
        onCancelButtonClick={onClose}
        onConfirmButtonClick={createAnnotation}
        confirmButtonTooltip={
          areMandatoryFieldsFilled ? undefined : FILL_MANDATORY_FIELDS_TOOLTIP
        }
      />
    </Stack>
  );
}
