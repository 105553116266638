import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  AnnotationEditorButtons,
  AnnotationEditorTypeSelection,
  AnnotationTypeDropdownProps,
  neutral,
} from "@faro-lotv/flat-ui";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";
import { Divider, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  EXTERNAL_ANNOTATION_DEFAULT_TITLE,
  FILL_MANDATORY_FIELDS_TOOLTIP,
} from "./annotation-props";
import { CreateAnnotationFormProps } from "./create-annotation-form";
import { ProcoreAnnotationForm } from "./procore-annotation-form";

type ProcoreAnnotationProps = CreateAnnotationFormProps &
  AnnotationTypeDropdownProps & {
    /** The BCF Service integration type that corresponds to the procore annotation to create */
    integrationType:
      | BcfServicesIntegrationType.procoreObservations
      | BcfServicesIntegrationType.procoreRfis;
  };

/**
 * @returns a Procore annotation creation form including the annotation type selection and the title
 */
export function ProcoreAnnotation({
  onClose,
  onSave,
  selectedAnnotationType,
  integrationType,
  onAnnotationTypeChange,
  annotationTypeOptions,
}: ProcoreAnnotationProps): JSX.Element {
  const { handleErrorWithToast } = useErrorHandlers();

  const [isSaving, setIsSaving] = useState(false);
  const [areMandatoryFieldsFilled, setAreMandatoryFieldsFilled] =
    useState(false);
  const [title, setTitle] = useState(EXTERNAL_ANNOTATION_DEFAULT_TITLE);
  const [assignee, setAssignee] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [dueDate, setDueDate] = useState<Date>();
  const [description, setDescription] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const [stage, setStage] = useState<string>("");
  const [topicType, setTopicType] = useState<string>("");

  useEffect(() => {
    // Reset the fields when the annotation type changes
    setTitle(EXTERNAL_ANNOTATION_DEFAULT_TITLE);
    setAssignee("");
    setStatus("");
    setDueDate(undefined);
    setDescription("");
    setPriority("");
    setStage("");
    setTopicType("");
  }, [integrationType]);

  const createAnnotation = useCallback(async () => {
    setIsSaving(true);
    try {
      await onSave({
        title,
        assignee,
        description,
        dueDate,
        status,
        externalAnnotationType: integrationType,
        priority,
        topicType,
        stage,
      });
    } catch (error) {
      handleErrorWithToast({ title: "Saving annotation failed", error });
    }
    setIsSaving(false);
  }, [
    onSave,
    title,
    assignee,
    description,
    dueDate,
    status,
    integrationType,
    priority,
    topicType,
    stage,
    handleErrorWithToast,
  ]);

  return (
    <Stack
      direction="column"
      sx={{
        width: "522px",
        backgroundColor: neutral[999],
        p: 2,
        borderRadius: "4px",
        pointerEvents: isSaving ? "none" : undefined,
        flexGrow: 1,
        gap: 3,
        // Allow the main content to scroll if there's not enough space
        overflowY: "auto",
        // but do not add an horizontal scrollbar when the main content needs to scroll
        overflowX: "clip",
      }}
    >
      <AnnotationEditorTypeSelection
        value={selectedAnnotationType}
        options={annotationTypeOptions}
        onChange={(e) => onAnnotationTypeChange(e.target.value)}
        disabled={isSaving}
      />
      <Divider sx={{ bgcolor: neutral[0] }} />
      <ProcoreAnnotationForm
        allowEdition={!isSaving}
        externalAnnotationType={integrationType}
        title={title}
        assignee={assignee}
        dueDate={dueDate}
        status={status}
        priority={priority}
        stage={stage}
        topicType={topicType}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
        onStageChange={setStage}
        onAssigneeChange={setAssignee}
        onDueDateChange={setDueDate}
        onStatusChange={setStatus}
        onPriorityChange={setPriority}
        onTopicTypeChange={setTopicType}
        onError={handleErrorWithToast}
        setAreMandatoryFieldsFilled={setAreMandatoryFieldsFilled}
      />
      <AnnotationEditorButtons
        isConfirmButtonDisabled={!areMandatoryFieldsFilled}
        disabled={isSaving}
        confirmButtonText="Create"
        onCancelButtonClick={onClose}
        onConfirmButtonClick={createAnnotation}
        confirmButtonTooltip={
          areMandatoryFieldsFilled ? undefined : FILL_MANDATORY_FIELDS_TOOLTIP
        }
      />
    </Stack>
  );
}
