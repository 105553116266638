import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { Matrix4, Quaternion, Vector3 } from "three";

/**
 * The point cloud color map analysis setting that will be saved in json file
 *
 * The difference between ColorMapAnalysisData and PointCloudAnalysis is as follows:
 * 1. id in PointCloudAnalysis will be used as analysis IElement id, no need to save it in json file
 * 2. everything in PointCloudAnalysis is in world coordinate system while in ColorMapAnalysisData,
 *    they are all in local coordinate relative to analysis IElement
 */
type ColorMapAnalysisData = Omit<
  PointCloudAnalysis,
  "id" | "isVisible" | "isDirty"
>;

/**
 * convert given PointCloudAnalysis into ColorMapAnalysisData and create the json file
 *
 * @param analysis the given PointCloudAnalysis object
 * @param worldTransform the world matrix of IElementAnalysis
 * @returns json file of the color map analysis data in local coordinate space
 */
export function createAnalysisJsonFile(
  analysis: PointCloudAnalysis,
  worldTransform: Matrix4,
): File {
  const colorMapAnalysisData = transformPointCloudAnalysisObject(
    analysis,
    worldTransform,
  );

  const jsonData = JSON.stringify(colorMapAnalysisData);

  return new File([jsonData], "analysis.json", {
    type: "text/plain",
  });
}

function transformPointCloudAnalysisObject(
  analysis: PointCloudAnalysis,
  worldTransform: Matrix4,
): ColorMapAnalysisData {
  const matrix = worldTransform.clone().invert();

  const TEMP_VEC1 = new Vector3();
  const TEMP_VEC2 = new Vector3();
  const rotation = new Quaternion();
  matrix.decompose(TEMP_VEC1, rotation, TEMP_VEC2);

  const localPolygon = analysis.polygonSelection.map((p) =>
    TEMP_VEC1.fromArray(p).applyMatrix4(matrix).toArray(),
  );

  const fittedPlane = analysis.fittedPlane
    ? {
        normal: TEMP_VEC1.fromArray(analysis.fittedPlane.normal)
          .applyQuaternion(rotation)
          .toArray(),
        point: TEMP_VEC1.fromArray(analysis.fittedPlane.point)
          .applyMatrix4(matrix)
          .toArray(),
      }
    : undefined;

  const fittedPlumbPlane = analysis.fittedPlumbPlane
    ? {
        normal: TEMP_VEC1.fromArray(analysis.fittedPlumbPlane.normal)
          .applyQuaternion(rotation)
          .toArray(),
        point: TEMP_VEC1.fromArray(analysis.fittedPlumbPlane.point)
          .applyMatrix4(matrix)
          .toArray(),
      }
    : undefined;

  return {
    polygonSelection: localPolygon,
    tolerance: analysis.tolerance,
    parentId: analysis.parentId,
    referencePlaneType: analysis.referencePlaneType,
    elevation: analysis.elevation,
    showReferencePlane: analysis.showReferencePlane,
    colormap: analysis.colormap,
    fittedPlane,
    fittedPlumbPlane,
  };
}
