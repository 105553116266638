import {
  CreateAreaEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  Alert,
  CropIcon,
  Dropdown,
  Option as DropdownOption,
  DropHandler,
  ExportIcon,
  FaroButton,
  FaroText,
  FaroTooltip,
  FileProgressCircular,
  RotateClockwiseIcon,
  TextField,
  ToolButton,
  ToolGroup,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { getFileExtension } from "@faro-lotv/foundation";
import { Box, Card, Grid, Stack, SxProps, Theme, Toolbar } from "@mui/material";
import {
  ChangeEvent,
  PropsWithChildren,
  DragEvent as ReactDragEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ConfirmAreaUpload } from "./confirm-area-upload";
import {
  MAX_ALLOWED_IMG_HEIGHT,
  MAX_ALLOWED_IMG_WIDTH,
  SupportedImgSheetFileExtensionList,
} from "./create-area-utils";
import { ImagePreview } from "./image-preview";
import { useCreateAreaLogic } from "./use-create-area-logic";

type CreateAreaMainBodyProps = {
  /** Optional styling applied to the component */
  sx?: SxProps<Theme>;

  /** true if sheet is a first one for the project */
  isFirstSheet?: boolean;
};

/**
 * @returns a component containing the input element to choose the name of the image,
 * the file drop handler and the button to create the sheet
 */
export function CreateAreaMainBody({
  sx,
  isFirstSheet = false,
}: CreateAreaMainBodyProps): JSX.Element {
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [fileInputEl, setFileInputEl] = useState<HTMLElement | null>(null);
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);

  const showEditButtons = useAppSelector(selectHasFeature(Features.AddArea));

  const {
    file,
    inputName,
    inputNameError,
    uploadProgress,
    hasDefaultGrid,
    isUsingGrid,
    hasImgBeenResized,
    setInputName,
    setFile,
    setDefaultGrid,
    createSheet,
    cancelUpload,
    widthToHeightRatio,
    rotation,
    setRotation,
    numberOfPdfPages,
    onUpdateSelectedPdfPage,
    selectedPdfPage,
  } = useCreateAreaLogic();

  const rotateImage = (): void => {
    setRotation(rotation + 90);
  };

  const scale = useMemo(() => {
    // we should never increase original size of image, only reduce if it doesn't fit to original box
    const reducedScaleFactor =
      widthToHeightRatio < 1 ? widthToHeightRatio : 1 / widthToHeightRatio;

    return rotation % 180 === 0 ? 1 : reducedScaleFactor;
  }, [rotation, widthToHeightRatio]);

  // This is needed to avoid continuously creating a new URL damaging performance
  const fileSource = useMemo(() => file && URL.createObjectURL(file), [file]);

  const openFileExplorer = useCallback(() => {
    fileInputEl?.click();
    setIsFileExplorerOpen(true);
  }, [fileInputEl]);

  const setFileFromDialog = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsFileExplorerOpen(false);
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }

      Analytics.track<CreateAreaEventProperties>(EventType.setFloorImage, {
        via: "custom-image",
        extension: getFileExtension(file.name) ?? "",
      });

      setFile(file);
    },
    [setFile],
  );

  const setFileFromDrop = useCallback(
    (e: ReactDragEvent<HTMLElement>) => {
      e.preventDefault();
      // Double check that we are importing one and only one file
      if (e.dataTransfer.files.length !== 1) return;

      setFile(e.dataTransfer.files[0]);
    },
    [setFile],
  );

  const closeFileDialog = useCallback(() => {
    setIsFileExplorerOpen(false);
  }, []);

  useEffect(() => {
    fileInputEl?.addEventListener("cancel", closeFileDialog);

    return () => {
      fileInputEl?.removeEventListener("cancel", closeFileDialog);
    };
  });

  // create array of entries for dropdown, starting from 1 as natural way for multi-pages documents
  const pagesSelector: DropdownOption[] | undefined = useMemo(
    () =>
      numberOfPdfPages
        ? Array.from({ length: numberOfPdfPages }, (_, index) => ({
            key: `${index + 1}`,
            value: `${index + 1}`,
            label: `${index + 1}`,
          }))
        : undefined,
    [numberOfPdfPages],
  );

  return (
    <>
      {showImagePreview && file && (
        <ImagePreview
          file={file}
          onClosePreview={() => setShowImagePreview(false)}
        />
      )}
      <Stack sx={sx} marginBottom={4}>
        {isFirstSheet && (
          <FaroText variant="heading20" sx={{ mb: "0.875rem" }}>
            Upload first sheet
          </FaroText>
        )}
        {!isFirstSheet && (
          <FaroText variant="bodyS" sx={{ mb: "0.875rem" }}>
            Uploading a sheet will create a new area in the project.
          </FaroText>
        )}

        <Grid item container alignItems="left">
          <Grid item xs={6}>
            <TextField
              label="Sheet name"
              placeholder="First level"
              fullWidth
              sx={{
                width: "95%",
                height: "2rem",
                backgroundColor: "white",
              }}
              error={inputNameError}
              onTextChanged={setInputName}
              text={inputName}
              inputProps={{
                "aria-label": "sheet name",
                onBlur: () => {
                  Analytics.track<CreateAreaEventProperties>(
                    EventType.changeFloorImage,
                  );
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {file && pagesSelector && (
              <Dropdown
                label="Page"
                options={pagesSelector}
                sx={{
                  width: "40%",
                  height: "2rem",
                  bottom: 3,
                }}
                value={selectedPdfPage.toString()}
                onChange={(event) => {
                  onUpdateSelectedPdfPage(parseInt(event.target.value, 10));
                }}
              />
            )}
          </Grid>
        </Grid>

        <Box component="div">
          {file ? (
            <Stack direction={{ md: "row" }} gap={1}>
              {/** Make the first card take up 70% of the space */}
              <CreateAreaCard sx={{ flexBasis: "70%" }}>
                <Stack direction="row">
                  <Box
                    component="img"
                    src={fileSource ?? ""}
                    alt={file.name}
                    sx={{
                      objectFit: "contain",
                      padding: 1,
                      boxShadow: "none",
                      mt: "1rem",
                      width: "90%",
                    }}
                    style={{
                      transform: `rotate(${rotation}deg) scale(${scale})`,
                      transformOrigin: "center",
                    }}
                    onClick={() => setShowImagePreview(true)}
                  />

                  <Box
                    component="div"
                    sx={{
                      width: "40px",
                    }}
                  >
                    {showEditButtons && (
                      <EditImageSubmenu onRotate={rotateImage} />
                    )}
                  </Box>
                </Stack>
              </CreateAreaCard>

              <CreateAreaCard
                sx={{
                  minHeight: "18.75rem",
                  justifyContent: "flex-start",
                  flexGrow: 1,
                }}
              >
                {hasImgBeenResized && (
                  <Alert
                    title="Image will be automatically rescaled."
                    description="The image exceeds the maximum supported resolution. The quality might be affected."
                    variant="warning"
                  />
                )}

                {uploadProgress ? (
                  <FileProgressCircular
                    progress={uploadProgress.progress}
                    expectedEnd={uploadProgress.expectedEnd}
                    fileName={isUsingGrid ? "Grid" : undefined}
                    file={file}
                    onCancel={cancelUpload}
                  />
                ) : (
                  <ConfirmAreaUpload
                    isUsingGrid={isUsingGrid}
                    onConfirm={createSheet}
                    onCancel={() => {
                      Analytics.track<CreateAreaEventProperties>(
                        EventType.changeFloorImage,
                      );

                      cancelUpload();
                    }}
                  />
                )}
              </CreateAreaCard>
            </Stack>
          ) : (
            <DropHandler
              sx={{
                height: "calc(100vh - 38rem)",
                maxHeight: "22.5rem",
                minHeight: "12.5rem",
                ":hover": {
                  border: "solid",
                  borderColor: "primary.main",
                  borderRadius: "0.25rem",
                },
              }}
              onDrop={setFileFromDrop}
              canBeDropped={(e) =>
                e.dataTransfer.items.length === 1 && !isFileExplorerOpen
              }
              enabled
              onClick={openFileExplorer}
            >
              <Card
                sx={{
                  minHeight: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  padding: 1,
                  boxShadow: "none",
                  ":hover": {
                    backgroundColor: ({ palette }) => `${palette.gray500}26`,
                  },
                }}
              >
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="3.75rem"
                  height="3.75rem"
                  borderRadius="50%"
                  sx={{ backgroundColor: "gray100" }}
                >
                  <ExportIcon sx={{ fontSize: "2rem" }} />
                </Box>
                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FaroText variant="bodyM">
                    Drag you data here, or &nbsp;
                  </FaroText>
                  <FaroText variant="hyperLink">
                    <input
                      aria-label="select sheet file"
                      ref={setFileInputEl}
                      type="file"
                      style={{ display: "none" }}
                      onChange={setFileFromDialog}
                      multiple={false}
                    />
                    <FaroText variant="hyperLink" sx={{ cursor: "pointer" }}>
                      select file
                    </FaroText>
                  </FaroText>
                  <FaroText variant="bodyM"> &nbsp; to upload</FaroText>
                </Stack>
                <FaroText variant="placeholder">
                  {`Supported formats ${SupportedImgSheetFileExtensionList.join(
                    ", ",
                  )}`}
                </FaroText>
                <FaroText variant="placeholder">
                  Maximum resolution: {MAX_ALLOWED_IMG_HEIGHT}x
                  {MAX_ALLOWED_IMG_WIDTH} px
                </FaroText>
              </Card>
            </DropHandler>
          )}
        </Box>
        {!file && hasDefaultGrid && (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="1rem"
            gap={2}
          >
            <FaroText variant="bodyM" sx={{ color: "gray850" }}>
              Don't have an image on hand? Click on the <b>Use Grid</b> button
              to get started right away.
            </FaroText>
            <FaroButton
              aria-label="use grid"
              variant="ghost"
              size="m"
              onClick={() => {
                Analytics.track<CreateAreaEventProperties>(
                  EventType.setFloorImage,
                  {
                    via: "default-grid",
                    extension: "",
                  },
                );

                setDefaultGrid();
              }}
              sx={{ whiteSpace: "nowrap" }}
            >
              Use Grid
            </FaroButton>
          </Stack>
        )}
      </Stack>
    </>
  );
}

interface CreateAreaCardProps {
  /** Styles applied to the card */
  sx?: SxProps<Theme>;
}

/** @returns Styled card container */
function CreateAreaCard({
  sx,
  children,
}: PropsWithChildren<CreateAreaCardProps>): JSX.Element {
  return (
    <Stack
      sx={{
        background: "white",
        border: "2px solid",
        borderColor: "gray300",
        borderRadius: "4px",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}

interface EditImageSubmenuProps {
  /** Callback executed to enable/disable crop */
  onEnableCrop?(): void;

  /** Callback executed on click rotation button */
  onRotate?(): void;
}

/** @returns the submenu for the clip scene tool */
export function EditImageSubmenu({
  onEnableCrop,
  onRotate,
}: EditImageSubmenuProps): JSX.Element {
  return (
    <Toolbar
      sx={{
        position: "relative",
        top: "45%",
        justifyContent: "center",
      }}
    >
      <ToolGroup orientation="vertical">
        <FaroTooltip title="Rotate image" placement="right">
          <ToolButton size="small" onClick={onRotate}>
            <RotateClockwiseIcon />
          </ToolButton>
        </FaroTooltip>

        <FaroTooltip title="Crop image" placement="right">
          <ToolButton size="small" onClick={onEnableCrop}>
            {
              // image cropping will be implemented in https://faro01.atlassian.net/browse/CADBIM-978
              // at the moment that button is hidden from users by feature flag "Add Area"
            }
            <CropIcon />
          </ToolButton>
        </FaroTooltip>
      </ToolGroup>
    </Toolbar>
  );
}
