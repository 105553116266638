import {
  CreateAnnotationShareLinkEventProperties,
  EditAnnotationEventProperties,
  EventType,
  GotoAnnotationProperties,
  OpenAnnotationAttachmentEventProperties,
  OpenAnnotationDetailsContextMenuEventProperties,
} from "@/analytics/analytics-events";
import { MeasurementValuesField } from "@/components/r3f/renderers/annotations/annotation-renderers/measurement-values-field";
import { HandleAnnotationViewerVariantReturn } from "@/components/r3f/renderers/annotations/annotation-renderers/use-handle-annotation-details-variant";
import { EditAnnotation } from "@/components/ui/annotations/edit-annotation";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAnnotationAssignees } from "@/hooks/use-annotation-assignees";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { useGoToAnnotation } from "@/hooks/use-go-to-annotation";
import { useShareAnnotation } from "@/hooks/use-share-annotation";
import { selectProjectIdForIntegrationType } from "@/store/integrations/integrations-selectors";
import {
  selectProjectUser,
  selectProjectUsers,
} from "@/store/project-selector";
import { selectMarkupAttachments } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  getAnnotationStatusDisplayName,
  getExternalMarkupProviderName,
} from "@faro-lotv/app-component-toolbox";
import { AnnotationViewer, FileDetails, UrlLink } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAttachment,
  isIElementExternalMarkup,
  isIElementMarkup,
  isIElementMarkupDueTime,
  isIElementMarkupState,
  isIElementMeasurePolygon,
  isIElementUrlLink,
  MarkupIElement,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
} from "@faro-lotv/project-source";
import {
  GetTopicResponse,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Box, Dialog, Stack, SxProps, Theme } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getBcfIntegrationTypeForIElementType } from "./external-annotation-utils";

interface MarkupAnnotationUIProps
  extends Omit<
    HandleAnnotationViewerVariantReturn,
    "onAnnotationViewerHovered"
  > {
  /** The details about a specific annotation */
  markup: MarkupIElement;

  /** Optional icon to render next to the UI. Used for single point annotations */
  icon?: JSX.Element;

  /** Pixel size to use for the icon */
  iconSize?: number;

  /** Optional style to apply to the Markup's UI */
  sx?: SxProps<Theme>;

  /** Callback executed when the copy to clipboard button is pressed */
  onCopyToClipboard?(): void;

  /** Callback executed when the user requests to delete the annotation. */
  onDelete(): void;
}

/** @returns the ui to interact with an annotation */
export function MarkupAnnotationUI({
  markup,
  icon,
  iconSize,
  sx,
  AnnotationViewerVariant,
  onAnnotationViewerExpanded,
  onAnnotationViewerClosed,
  onCopyToClipboard,
  onDelete,
}: MarkupAnnotationUIProps): JSX.Element | null {
  const [externalMarkupData, setExternalMarkupData] =
    useState<GetTopicResponse>();

  const externalMarkup = useAppSelector(
    selectChildDepthFirst(markup, isIElementExternalMarkup),
  );

  const { bcfServicesApiClient } = useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();

  const integrationType = externalMarkup
    ? getBcfIntegrationTypeForIElementType(externalMarkup.type)
    : undefined;

  const integrationProjectId = useAppSelector(
    integrationType
      ? selectProjectIdForIntegrationType(integrationType)
      : () => undefined,
  );

  useEffect(() => {
    async function fetchExternalMarkupData(): Promise<void> {
      setExternalMarkupData(undefined);
      try {
        assert(integrationType, "Integration type is not defined");
        assert(
          externalMarkup?.externalIssueId,
          "External issue id is not defined",
        );
        assert(integrationProjectId, "Integration project id is not defined");
        const topic = await bcfServicesApiClient.getTopic(
          integrationType,
          integrationProjectId,
          externalMarkup.externalIssueId,
        );
        setExternalMarkupData(topic);
      } catch (error) {
        handleErrorWithToast({
          title: "Error fetching external markup data",
          error,
        });
      }
    }

    if (
      externalMarkup?.externalIssueId &&
      integrationType &&
      integrationProjectId
    ) {
      fetchExternalMarkupData();
    }
  }, [
    bcfServicesApiClient,
    externalMarkup,
    handleErrorWithToast,
    integrationProjectId,
    integrationType,
  ]);

  const sphereMarkup = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkup),
  );

  // Check if the markup has an attachment
  const hasAttachment = useAppSelector(
    (state) => selectMarkupAttachments(markup)(state).length > 0,
  );

  // Collect the info related to this annotation
  const dueDate = useAppSelector(
    selectChildDepthFirst(sphereMarkup, isIElementMarkupDueTime),
  );
  const state = useAppSelector(
    selectChildDepthFirst(sphereMarkup, isIElementMarkupState),
  );
  const mainAssignee = useAnnotationAssignees(sphereMarkup).at(0);
  const projectUsers = useAppSelector(selectProjectUsers);

  // The creator of the annotation
  const projectUser = useAppSelector(
    selectProjectUser(sphereMarkup?.createdBy),
  );
  const currentUser = useAppSelector(selectCurrentUser);
  const createdBy =
    currentUser?.id === sphereMarkup?.createdBy ? currentUser : projectUser;

  // For now use the first assignee
  const assigneeId = useMemo(
    () =>
      mainAssignee
        ? projectUsers.find((user) => user.id === mainAssignee.id)?.id
        : undefined,
    [mainAssignee, projectUsers],
  );

  const { canWriteAnnotations } = useAnnotationPermissions();
  const [isEditing, setIsEditing] = useState(false);

  const shareAnnotation = useShareAnnotation(markup.id);

  const measurement = useAppSelector(
    selectAncestor(sphereMarkup, isIElementMeasurePolygon),
  );
  const isMeasurement = !!measurement;

  const attachmentsElements = useAppSelector(
    selectChildrenDepthFirst(sphereMarkup, isIElementAttachment),
    isEqual,
  );

  const attachments = useMemo<FileDetails[]>(
    () =>
      attachmentsElements.map((el) => ({
        id: el.id,
        fileName: el.name,
        fileSize: el.fileSize,
        date: el.createdAt,
        urlOrFile: el.uri,
        thumbnailUrl: el.thumbnailUri,
      })),
    [attachmentsElements],
  );

  const trackContextMenuOpening = useCallback(() => {
    Analytics.track<OpenAnnotationDetailsContextMenuEventProperties>(
      EventType.openAnnotationDetailsContextMenu,
      {
        via: AnnotationViewerVariant,
      },
    );
  }, [AnnotationViewerVariant]);

  const linkElements = useAppSelector(
    selectChildrenDepthFirst(sphereMarkup, isIElementUrlLink),
    isEqual,
  );
  const links = useMemo<UrlLink[] | undefined>(() => {
    if (linkElements.length === 0) return;
    return linkElements.map((el) => ({
      label: el.descr ?? el.name,
      href: el.uri,
    }));
  }, [linkElements]);

  const tags = useMemo(
    () => markup.labels?.map((l) => ({ id: l.id, label: l.name })),
    [markup.labels],
  );

  const isFull = AnnotationViewerVariant === "full";

  const onIconClicked = useCallback(() => {
    if (isFull) {
      onAnnotationViewerClosed();
    } else {
      onAnnotationViewerExpanded();
    }
  }, [isFull, onAnnotationViewerClosed, onAnnotationViewerExpanded]);

  const goToAnnotation = useGoToAnnotation(markup);

  const shouldRenderDetails =
    AnnotationViewerVariant !== "hidden" &&
    AnnotationViewerVariant !== "collapsed";

  // We are testing here externalMarkupData because we want to display the title of the markup if the fetch is failing
  const externalMarkupAnnotationTitle =
    externalMarkupData?.title ?? `External annotation id: ${markup.name}`;
  const finalTitle = externalMarkup
    ? externalMarkupAnnotationTitle
    : markup.name;
  const finalAssignee = externalMarkup ? undefined : mainAssignee;
  const finalCreatedBy = externalMarkup ? undefined : createdBy;
  const externalMarkupAnnotationDueDate = externalMarkupData?.due_date
    ? new Date(externalMarkupData.due_date)
    : undefined;
  const sphereDueDate = dueDate ? new Date(dueDate.value) : undefined;
  const finalDueDate = externalMarkup
    ? externalMarkupAnnotationDueDate
    : sphereDueDate;
  const finalDescription = externalMarkup
    ? externalMarkupData?.description
    : markup.descr;
  const finalStatus = externalMarkup
    ? externalMarkupData?.topic_status?.name
    : getAnnotationStatusDisplayName(state?.value);
  const finalSyncService = externalMarkup
    ? getExternalMarkupProviderName(externalMarkup)
    : undefined;
  const finalHasAttachment = externalMarkup ? false : hasAttachment;
  const finalAttachments = externalMarkup ? undefined : attachments;
  const finalLinks = externalMarkup ? undefined : links;
  const finalTags = externalMarkup
    ? externalMarkupData?.labels.map((l) => ({ id: l.id, label: l.name }))
    : tags;
  const finalIsMeasurement = externalMarkup ? false : isMeasurement;

  return (
    <>
      <Stack
        direction="row"
        alignItems="top"
        sx={{
          width: "max-content",
          ...sx,
        }}
      >
        <Box
          component="div"
          sx={{
            width: iconSize,
            height: iconSize,
            visibility: icon ? "visible" : "collapse",
            cursor: "pointer",
          }}
          onClick={onIconClicked}
        >
          {icon}
        </Box>
        {shouldRenderDetails && (
          <AnnotationViewer
            onDescriptionError={(error) =>
              handleErrorWithToast({ title: "Error in description", error })
            }
            onExpandButtonClick={() => {
              Analytics.track(EventType.expandAnnotationDetails);
              onAnnotationViewerExpanded();
            }}
            onCloseButtonClick={() => {
              Analytics.track(EventType.closeAnnotationDetails);
              onAnnotationViewerClosed();
            }}
            onGoToButtonClick={() => {
              Analytics.track<GotoAnnotationProperties>(
                EventType.gotoAnnotation,
                { via: "3d scene" },
              );
              goToAnnotation();
            }}
            onShareButtonClick={() => {
              Analytics.track<CreateAnnotationShareLinkEventProperties>(
                EventType.createAnnotationShareLink,
                {
                  via: AnnotationViewerVariant,
                },
              );

              shareAnnotation();
            }}
            onAttachmentOpened={(fileType: string) =>
              Analytics.track<OpenAnnotationAttachmentEventProperties>(
                EventType.openAnnotationAttachment,
                {
                  fileType,
                },
              )
            }
            // External markups are not supported yet
            canEdit={canWriteAnnotations && !externalMarkup}
            onContextMenuOpen={trackContextMenuOpening}
            onEdit={() => {
              Analytics.track<EditAnnotationEventProperties>(
                EventType.editAnnotation,
                {
                  via: "3d scene",
                },
              );

              setIsEditing(true);
            }}
            onDelete={onDelete}
            variant={AnnotationViewerVariant}
            title={finalTitle}
            assignee={finalAssignee}
            createdBy={finalCreatedBy}
            dueDate={finalDueDate}
            description={finalDescription}
            status={finalStatus}
            externalAnnotationType={finalSyncService}
            hasAttachment={finalHasAttachment}
            isMeasurement={finalIsMeasurement}
            onCopyToClipboard={onCopyToClipboard}
            files={finalAttachments}
            links={finalLinks}
            tags={finalTags}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </AnnotationViewer>
        )}
      </Stack>
      {isEditing && sphereMarkup !== undefined && (
        <Dialog
          open
          PaperProps={{
            sx: {
              padding: 0,
              backgroundColor: "transparent",
              overflow: "hidden",
              boxShadow: "none",
            },
          }}
        >
          <EditAnnotation
            iElement={sphereMarkup}
            title={sphereMarkup.name}
            description={sphereMarkup.descr ?? undefined}
            assignee={assigneeId}
            status={state?.value ?? undefined}
            dueDate={dueDate?.value ? new Date(dueDate.value) : undefined}
            onClose={() => setIsEditing(false)}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </EditAnnotation>
        </Dialog>
      )}
    </>
  );
}
