import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { Banner, FaroButton, FaroText } from "@faro-lotv/flat-ui";
import {
  isDefinedAndLoaded,
  MaybeLoading,
  validateEnumValue,
} from "@faro-lotv/foundation";
import { Stack } from "@mui/system";
import { useMemo } from "react";

const CONTACT_SUPPORT =
  "https://knowledge.faro.com/Essentials/General/FARO_Global_Technical_Support_and_Hours_of_Availability";

/**
 * The possible errors that can occur during registration.
 * These errors are used to determine the message to show to the user.
 */
export enum RegistrationError {
  NoError = "NO_ERROR",
  NoRegistration = "NO_REGISTRATION",
  MissingConnections = "MISSING_CONNECTIONS",
  PublishFailed = "PUBLISH_FAILED",
  MergeFailed = "MERGE_FAILED",
  OtherError = "OTHER_ERROR",
}

type RegisteredStatusBannerProps = {
  /** The registration report to show */
  registrationReport: MaybeLoading<MultiRegistrationReport>;
  /** Whether the registration is rerunning */
  isRegistrationRerunning: boolean;
  /** Callback function to go to the registration step */
  onBackToRegistrationStepKey(): void;
  /** Callback function to rerun a registration */
  onRerunRegistration(): void;
};

/**
 * @returns A banner indicating the status of a completed registration.
 */
export function RegisteredStatusBanner({
  registrationReport,
  isRegistrationRerunning,
  onBackToRegistrationStepKey,
  onRerunRegistration,
}: RegisteredStatusBannerProps): JSX.Element | null {
  const registrationError = useMemo(() => {
    if (isDefinedAndLoaded(registrationReport)) {
      const { additionalData } = registrationReport;
      if (additionalData) {
        return validateEnumValue(additionalData.error, RegistrationError)
          ? additionalData.error
          : RegistrationError.OtherError;
      }
    }

    return RegistrationError.NoError;
  }, [registrationReport]);

  switch (registrationError) {
    case RegistrationError.NoRegistration:
      return <RegistrationFailedMessage />;
    case RegistrationError.MissingConnections:
      return (
        <MissingConnectionsMessage
          onBackToRegistrationStepKey={onBackToRegistrationStepKey}
        />
      );
    case RegistrationError.PublishFailed:
      return (
        <PublishFailedMessage
          isRegistrationRerunning={isRegistrationRerunning}
          onRerunRegistration={onRerunRegistration}
        />
      );
    case RegistrationError.MergeFailed:
      return <MergeFailedMessage />;
    case RegistrationError.OtherError:
      return <OtherErrorMessage />;
    case RegistrationError.NoError:
      return (
        <Banner variant="success" title="Registration completed">
          Review the result and publish the data set.
        </Banner>
      );
  }
}

/**
 * @returns Message to show when the registration failed.
 */
function RegistrationFailedMessage(): JSX.Element {
  return (
    <Banner variant="error" title="Registration Failed: ">
      We could not register your data. You can either upload new scans and
      restart registration or publish anyways.
    </Banner>
  );
}

type MissingConnectionsMessageProps = Pick<
  RegisteredStatusBannerProps,
  "onBackToRegistrationStepKey"
>;

/**
 * @returns  Message to show when some scans are not connected to the others via registrations.
 */
function MissingConnectionsMessage({
  onBackToRegistrationStepKey,
}: MissingConnectionsMessageProps): JSX.Element {
  return (
    <Banner variant="warning" title="Unconnected scans found: ">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FaroText variant="bodyL">
          Publishing is not recommended, as not all scans are connected to a
          single graph. Review and edit the issues before publishing.
        </FaroText>
        <FaroButton
          variant="ghost"
          size="m"
          onClick={onBackToRegistrationStepKey}
        >
          Edit
        </FaroButton>
      </Stack>
    </Banner>
  );
}

type PublishFailedMessageProps = Pick<
  RegisteredStatusBannerProps,
  "isRegistrationRerunning" | "onRerunRegistration"
>;

/**
 * @returns Message to show when an error occurred during publishing.
 */
function PublishFailedMessage({
  isRegistrationRerunning,
  onRerunRegistration,
}: PublishFailedMessageProps): JSX.Element {
  return (
    <Banner variant="error" title="Publishing Failed: ">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FaroText variant="bodyL">
          We could not publish your prepared data, as the content changed while
          working on it. Please restart registration to fix the issue.
        </FaroText>
        <FaroButton
          variant="ghost"
          size="m"
          isLoading={isRegistrationRerunning}
          onClick={onRerunRegistration}
        >
          Restart Registration
        </FaroButton>
      </Stack>
    </Banner>
  );
}

/**
 * @returns Message to show when merging the point clouds failed.
 */
function MergeFailedMessage(): JSX.Element {
  return (
    <Banner variant="error" title="Publishing Failed: ">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FaroText variant="bodyL">
          A problem occurred and has been reported to our team, we are working
          to resolve it as quickly as possible. Please try again later. If the
          issue persists, contact support for assistance.
        </FaroText>
        <FaroButton variant="ghost" href={CONTACT_SUPPORT}>
          Contact Support
        </FaroButton>
      </Stack>
    </Banner>
  );
}

/**
 * @returns Message to show on an unknown error.
 */
function OtherErrorMessage(): JSX.Element {
  return (
    <Banner variant="error" title="Publishing failed: ">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FaroText variant="bodyL">
          A problem occurred and has been reported to our team, we are working
          to resolve it as quickly as possible. Please try again later. If the
          issue persists, contact support for assistance.
        </FaroText>
        <FaroButton variant="ghost" href={CONTACT_SUPPORT}>
          Contact Support
        </FaroButton>
      </Stack>
    </Banner>
  );
}
